import React from "react";

import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";

import { CURRENT_SWT_SEASON } from "../shared/constants";
import { useScreenSize } from "../shared/hooks/useScreenSize";
import { momentDatetime } from "../shared/utils/dateTime";
import Loading from "./Loading";

const { Option } = Select;

const ApplicationForm = (props) => {
  const [screenSize, isMobile] = useScreenSize();
  const {
    form,
    item = {},
    universities = [],
    onSubmit,
    onClose,
    isLoading = false,
  } = props;
  const { employer = {} } = item;
  const { getFieldDecorator } = form;

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  const { Panel } = Collapse;

  return (
    <Drawer
      title={item.fullname ? item.fullname : "Добави студент"}
      width={isMobile ? screenSize : 720}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
      visible
    >
      <Loading isLoading={isLoading}>
        <Form layout="vertical" spellCheck={false} onSubmit={handleSubmit}>
          <Collapse defaultActiveKey={["1"]} accordion>
            <Panel header="Лична информация" key="1">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Име">
                    {getFieldDecorator("firstname", {
                      initialValue: item.firstname,
                      rules: [
                        { required: true, message: "Моля попълнете име" },
                      ],
                    })(<Input placeholder="Име" />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Презиме">
                    {getFieldDecorator("middlename", {
                      initialValue: item.middlename,
                    })(
                      <Input style={{ width: "100%" }} placeholder="Презиме" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Фамилия">
                    {getFieldDecorator("lastname", {
                      initialValue: item.lastname,
                      rules: [{ required: true, message: "Въведете фамилия" }],
                    })(
                      <Input style={{ width: "100%" }} placeholder="Фамилия" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Ел поща">
                    {getFieldDecorator("email", {
                      initialValue: item.email,
                      rules: [{ required: true, message: "Въведете ел. поща" }],
                    })(
                      <Input style={{ width: "100%" }} placeholder="Ел. поща" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Телефон">
                    {getFieldDecorator("phone", {
                      initialValue: item.phone,
                      rules: [{ message: "Въведете телефон" }],
                    })(
                      <Input style={{ width: "100%" }} placeholder="Телефон" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Пол">
                    {getFieldDecorator("sex", {
                      initialValue: item.sex,
                      rules: [{ required: true, message: "Моля изберете пол" }],
                    })(
                      <Select placeholder="Male/Female">
                        <Option value="M">Male</Option>
                        <Option value="F">Female</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item label="ЕГН">
                    {getFieldDecorator("egn", {
                      initialValue: item.egn,
                    })(<Input style={{ width: "100%" }} placeholder="ЕГН" />)}
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item label="Дата на раждане">
                    {getFieldDecorator("dob", {
                      initialValue: momentDatetime(item.dob),
                    })(
                      <DatePicker
                        format="DD/MM/YYYY"
                        style={{ width: "100%" }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item label="Място на раждане">
                    {getFieldDecorator("city", {
                      initialValue: item.city,
                    })(
                      <Input
                        style={{ width: "100%" }}
                        placeholder="По паспорт"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Паспорт #">
                    {getFieldDecorator("passport", {
                      initialValue: item.passport,
                    })(
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Номер на паспорт"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item label="Дата на издаване">
                    {getFieldDecorator("passportValidFrom", {
                      initialValue: momentDatetime(item.passportValidFrom),
                    })(
                      <DatePicker
                        format="DD/MM/YYYY"
                        style={{ width: "100%" }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item label="Дата на валидност">
                    {getFieldDecorator("passportValidTill", {
                      initialValue: momentDatetime(item.passportValidTill),
                    })(
                      <DatePicker
                        format="DD/MM/YYYY"
                        style={{ width: "100%" }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="От къде научи за нас">
                    {getFieldDecorator("source", {
                      initialValue: item.source ?? "organic",
                      rules: [
                        { required: true, message: "Моля изберете източник" },
                      ],
                    })(
                      <Select placeholder="Organic">
                        <Option value="organic">Органичен</Option>
                        <Option value="referal">Препоръка</Option>
                        <Option value="facebook-leads">Facebook (Leads)</Option>
                        <Option value="facebook-ads">Facebook (Ads)</Option>
                        <Option value="google-ads">Google (Ads)</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel header="Информация за програма" key="2">
              <Row gutter={16}>
                <Col xs={12} sm={12} md={6}>
                  <Form.Item label="Статус">
                    {getFieldDecorator("status", {
                      initialValue: item.status || "initial",
                    })(
                      <Select>
                        <Option value="initial">Записан</Option>
                        <Option value="cancelled">Отказал се</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6}>
                  <Form.Item label="Сезон">
                    {getFieldDecorator("swtSeason", {
                      initialValue: item.swtSeason || CURRENT_SWT_SEASON,
                      rules: [
                        { required: true, message: "Моля изберете сезон" },
                      ],
                    })(
                      <Select placeholder="Сезон">
                        <Option value="2022">2022</Option>
                        <Option value="2023">2023</Option>
                        <Option value="2024">2024</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={6}>
                  <Form.Item label="Спонсор">
                    {getFieldDecorator("sponsor", {
                      initialValue: item.sponsor,
                    })(
                      <Select placeholder="Избери спонсор">
                        <Option value="CSB">CSB</Option>
                        <Option value="Intrax">Intrax</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Form.Item label="Цена">
                    {getFieldDecorator("price", {
                      initialValue: item.price,
                    })(
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Цена в долари"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={12} sm={12} md={6}>
                  <Form.Item label="Оферта">
                    {getFieldDecorator("placementType", {
                      initialValue: item.placementType,
                    })(
                      <Select placeholder="Избери оферта">
                        <Option value="Self">Self-placement</Option>
                        <Option value="Full">Full-placement</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12} md={6}>
                  <Form.Item label="DS2019">
                    {getFieldDecorator("ds2019", {
                      initialValue: item.ds2019,
                    })(
                      <Input
                        style={{ width: "100%" }}
                        placeholder="DS2019 номер"
                      />
                    )}
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12}>
                  <Form.Item label="Допълнителен разход (в долари)">
                    {getFieldDecorator("additionalCost", {
                      initialValue: item.additionalCost,
                    })(
                      <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Напр: агенти за раб. оферта"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24}>
                  {getFieldDecorator("priorVisa", {
                    initialValue: item.priorVisa,
                    valuePropName: "checked",
                  })(<Checkbox>Предишна виза</Checkbox>)}
                </Col>

                <Col xs={24} sm={24} md={24}>
                  {getFieldDecorator("priorRefusal", {
                    initialValue: item.priorRefusal,
                    valuePropName: "checked",
                  })(<Checkbox>Предишен отказ</Checkbox>)}
                </Col>
                <Col xs={24} sm={24} md={24}>
                  {getFieldDecorator("credit", {
                    initialValue: item.credit,
                    valuePropName: "checked",
                  })(<Checkbox>Кредит</Checkbox>)}
                </Col>
                <Col xs={24} sm={24} md={24}>
                  {getFieldDecorator("insurance", {
                    initialValue: item.insurance,
                    valuePropName: "checked",
                  })(<Checkbox>Застраховка (visa)</Checkbox>)}
                </Col>
                <Col xs={24} sm={24} md={24}>
                  {getFieldDecorator("insuranceFull", {
                    initialValue: item.insuranceFull,
                    valuePropName: "checked",
                  })(<Checkbox>Застраховка (visa + self)</Checkbox>)}
                </Col>
                <Col xs={24} sm={24} md={24}>
                  {getFieldDecorator("insuranceEnrolled", {
                    initialValue: item.insuranceEnrolled,
                    valuePropName: "checked",
                  })(<Checkbox>Застраховката е сключена с партньор</Checkbox>)}
                </Col>
              </Row>
            </Panel>
            <Panel header="Университет" key="4">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item label="Университет">
                    {getFieldDecorator("university", {
                      initialValue: item.university?._id,
                    })(
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder="Избери университет"
                      >
                        {universities
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((university) => (
                            <Option key={university._id} value={university._id}>
                              {university.name}
                            </Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Form.Item label="Година">
                    {getFieldDecorator("yearOfStudy", {
                      initialValue: item.yearOfStudy,
                    })(
                      <Select>
                        <Option value="1">1ва година</Option>
                        <Option value="2">2ра година</Option>
                        <Option value="3">3та година</Option>
                        <Option value="4">4та година</Option>
                        <Option value="5">5та година</Option>
                        <Option value="6">6та година</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <Form.Item label="Степен">
                    {getFieldDecorator("degree", {
                      initialValue: item.degree,
                    })(
                      <Select>
                        <Option value="bachelor">Bachelor</Option>
                        <Option value="master">Master</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item label="Специалност">
                    {getFieldDecorator("universityMajor", {
                      initialValue: item.universityMajor,
                    })(
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Напр: Архитектура"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item label="Допълнителна информация">
                    {getFieldDecorator("notes", {
                      initialValue: item.notes,
                    })(
                      <Input.TextArea
                        rows={3}
                        placeholder="Адрес, неустойки и т.н."
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel header="Работодател" key="3">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Работодател">
                    {getFieldDecorator("employer.name", {
                      initialValue: employer.name,
                    })(<Input style={{ width: "100%" }} />)}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Позиця">
                    {getFieldDecorator("employer.position", {
                      initialValue: employer.position,
                    })(
                      <Input style={{ width: "100%" }} placeholder="Позиция" />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="Телефон">
                    {getFieldDecorator("employer.phone", {
                      initialValue: employer.phone,
                    })(
                      <Input style={{ width: "100%" }} placeholder="Телефон" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item label="Начална дата">
                    {getFieldDecorator("employer.startDate", {
                      initialValue: momentDatetime(employer.startDate),
                    })(
                      <DatePicker
                        format="DD/MM/YYYY"
                        style={{ width: "100%" }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={8}>
                  <Form.Item label="Крайна дата">
                    {getFieldDecorator("employer.endDate", {
                      initialValue: momentDatetime(employer.endDate),
                    })(
                      <DatePicker
                        format="DD/MM/YYYY"
                        style={{ width: "100%" }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={16}>
                  <Form.Item label="Адрес">
                    {getFieldDecorator("employer.address", {
                      initialValue: employer.address,
                    })(
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Адрес на работодател"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right",
            }}
          >
            <Button type="primary" htmlType="submit">
              Запази
            </Button>
          </div>
        </Form>
      </Loading>
    </Drawer>
  );
};

export default Form.create()(ApplicationForm);
