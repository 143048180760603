import React, { useEffect, useState } from "react";

import { Col, Form, Input, Row, Spin } from "antd";
import axios from "axios";

import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import { PDFViewer } from "../components/PDFViewer";
import { StudentRemoteSelect } from "../components/StudentRemoteSelect";
import { useSeason } from "../shared/hooks/useSeason";

// TODO: https://github.com/react-pdf/site/blob/d4f1f65f6be800cc144f26a935237a98c56e8e85/src/components/PDFViewer.js

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    width: "100%",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  author: {
    fontSize: 14,
    textAlign: "center",
    // marginBottom: 20,
  },
  subtitle: {
    fontSize: 14,
    margin: 12,
    textAlign: "center",
    marginBottom: 50,
  },
  text: {
    fontSize: 14,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 200,
    // width: 128,
    // height: 128,
  },
  header: {
    fontSize: 14,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "table",
    width: "auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
    textAlign: "left",
  },
  tableCell: {
    // margin: "auto",
    width: "75%",
    marginTop: 5,
    fontSize: 14,
    textAlign: "left",
    display: "table-cell",
  },
});

const itemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

const Certificates = ({ form }) => {
  const [student, setStudent] = useState();
  const [students, setStudents] = useState();
  const [isFetching, setIsFetching] = useState(true);

  const { swtSeason } = useSeason();

  const { getFieldDecorator } = form;

  useEffect(() => {
    axios.get(`/api/applications?swtSeason=${swtSeason}`).then((res) => {
      setStudents(res.data);
      setIsFetching(false);
    });
  }, [swtSeason]);

  const onChange = (value) => {
    const student = students.find((student) => student.id === value);
    form.setFieldsValue({
      number: "",
      faculty_no: "",
      semester: "",
      major: student.universityMajorEN,
    });
    setStudent(student);
  };

  const formatYearOfStudy = (year) => {
    if (!year) return "";
    switch (year % 10) {
      case 1:
        return `${year}st`;
      case 2:
        return `${year}nd`;
      case 3:
        return `${year}rd`;
      default:
        return `${year}th`;
    }
  };

  if (isFetching) return <Spin />;

  return (
    <Row gutter={16}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <Form layout="horizontal" spellCheck={false} {...itemLayout}>
          <Form.Item label="Студент">
            <StudentRemoteSelect students={students} onChange={onChange} />
          </Form.Item>
          <Form.Item label="Номер и дата">
            {getFieldDecorator(
              "number",
              {}
            )(
              <Input placeholder="Въведете номер и дата, пр: 12 / 01.12.2012" />
            )}
          </Form.Item>
          <Form.Item label="Записан за">
            {getFieldDecorator(
              "semester",
              {}
            )(<Input placeholder="2023/2024 winter semester" />)}
          </Form.Item>
          <Form.Item label="Факултет номер">
            {getFieldDecorator(
              "faculty_no",
              {}
            )(<Input placeholder="Въведете факултетен номер" />)}
          </Form.Item>

          <Form.Item label="Специалност">
            {getFieldDecorator(
              "major",
              {}
            )(<Input placeholder="Въведете специалност" />)}
          </Form.Item>
        </Form>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        {!student && <>Изберете студент</>}
        {student && (
          <PDFViewer student={student}>
            <Document>
              <Page style={styles.body}>
                {student.university && (
                  <Image
                    style={styles.image}
                    src={`/api/upload/logos/${student.university.logo.name}`}
                  />
                )}
                <Text style={styles.author}>Department of Education</Text>
                <Text style={styles.subtitle}>{student.university?.name}</Text>
                <Text style={styles.title}>Certificate</Text>
                <Text style={styles.subtitle}>
                  {form.getFieldValue("number")}
                </Text>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Surname, Name</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {student.lastname}, {student.firstname}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Faculty number</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {form.getFieldValue("faculty_no")}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>ID Number</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{student.egn}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>City</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {student.university?.city}
                      </Text>
                    </View>
                  </View>
                </View>
                <Text
                  style={{ ...styles.header, textAlign: "left", marginTop: 20 }}
                >
                  Is full time student
                </Text>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Course</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {formatYearOfStudy(student.yearOfStudy)}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Semester</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {form.getFieldValue("semester")}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Form of education</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>full-time regular</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Education level</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{student.degree}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>Academic major</Text>
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>
                        {form.getFieldValue("major")}
                      </Text>
                    </View>
                  </View>
                </View>

                <Text style={{ ...styles.text, marginTop: 40 }}>
                  This certificate is issued to certify, that the student is:
                  full time student
                </Text>
                <Image
                  style={{ height: 50, width: 152, marginTop: 20 }}
                  src="/stamp.png"
                />
              </Page>
            </Document>
          </PDFViewer>
        )}
      </Col>
    </Row>
  );
};

export default Form.create()(Certificates);

// export default Certificates;
