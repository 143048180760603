import React, {
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
} from 'antd';
import axios from 'axios';

import {
  ExchangeCalulator,
  ExchangeRates,
  Loading,
} from '../components';
import { formatDatetime } from '../shared/utils/dateTime';

const Dashboard = (props) => {
  const [currency, setCurrency] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios.get("/api/currencies/USD").then((response) => {
      setCurrency(response.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <div style={{ padding: "16px" }}>
      <Loading isLoading={isLoading}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div style={{ marginBottom: "1em" }}>
              <i>Курс на ПИБ към {formatDatetime(currency.updatedAt)}</i>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={16} md={12} lg={8} xl={6}>
            <ExchangeRates
              title="Валутни курсове – USD"
              bordered={true}
              stats={[
                { title: "Купува", value: currency.buying },
                { title: "Продава", value: currency.selling },
              ]}
            />
          </Col>

          <Col xs={24} sm={16} md={12} lg={8} xl={6}>
            <ExchangeCalulator
              title="Валутен калкулатор – USD/BGN"
              bordered={true}
              defaultCurrency={"usd"}
              defaultValue={375}
              rate={currency.selling}
            />
          </Col>
        </Row>
      </Loading>
    </div>
  );
};

export default Dashboard;
